import Accordion from "./Accordion";
import Faq from "./Faq";
import ControlledAccordions from "./Faq";
import phone from '../image/img_95.png'
import sms from '../image/img_96.png'
import write from '../image/img_97.png'
import Footer from "./Footer";
import React from "react";

export default function Questions () {
    return (
        <div>
        <div className={'accordion-example'}>
            <Faq/>
        </div>
        </div>

    )
}