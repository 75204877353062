import CodeInput from "../CodeInput";
import PhoneInput from "../PhoneInput";
import React, { useState } from 'react';

export default function PopupTicket4 ({number,text}) {

    function openPopupTicket4() {
        closePopup2()
        document.getElementById("popup-ticket-4").style.display = "block";
    }
    function openPopupAuth() {
        closePopup2()
        document.getElementById("popup-auth").style.display = "block";
    }
    function openPopup() {
        closePopup2()
        document.getElementById("popup").style.display = "block";
    }


    function closePopup2() {
        document.getElementById("popup-ticket-4").style.display = "none";
        localStorage.removeItem('number');
        document.body.classList.remove("no-scroll");
        reloadPage1()
    }

    function reloadPage() {
        window.location.reload();
    }
// Функция обновления страницы
    function reloadPage1() {
        window.location.href = window.location.href;
    }
    window.addEventListener('beforeunload', function(event) {
        // Удаляем элемент 'number' из localStorage перед обновлением страницы
        localStorage.removeItem('number');
    });
    const [value, setValue] = useState('');

    const handleInputChange = (event) => {
        const inputValue = event.target.value;
        const sanitizedValue = inputValue.replace(/[^\d]/g, '');
        setValue(sanitizedValue);
    }
    const [showTooltip, setShowTooltip] = useState(false);

    const openTooltip = () => {
        setShowTooltip(true);
    };

    const closeTooltip = () => {
        setShowTooltip(false);
    };
    const toggleTooltip = () => {
        setShowTooltip(!showTooltip);
    };


    return (
        <div id="popup-ticket-4" className="popup">
            <div className={'blur-filter'}>
                <div className="popup-ticket1 its-4" id={'popup-content'}>
                    <span className="close" onClick={closePopup2}>&times;</span>
                    <form action={''}  id={'form-add-ticket4'} className={'form-register'} >
                        <h1 className={'popup-h1 for-its-4'}>Регистрация билета</h1>
                        <label className={'bilet-ticket-2-p'}>Билет</label>
                        <label className={'bilet-ticket-2-p2'} id='ticket-for-number4'></label>
                        <label className={'bilet-ticket-2-p3'}>не продан: регистрация невозможна </label>
                        <p className={'bilet-ticket-2-p5'}>Проверьте корректность ввода номера лотерейного билета или обратитесь в точку продаж</p>
                    </form>

                </div>
            </div>

        </div>

    )
}